export default {
  // The keys defined here will override those in the base locale file, for example:
  // 'theme_vendor': 'D-BAT'
  theme_vendor: 'iFlex Stretch Studios',
  'customers.TermsDialog.accept':
    'By clicking ‘Accept’ you verify that you have read and accepted the policy and terms of {customerName, select, theme_vendor {} other {{customerName} and}} Upper Hand Inc.',
  'client_theme.ThemeSidebar.subHeader': 'Welcome',
  'client_theme.ThemeSidebar.mission':
    'iFlex Stretch Studios helps you move more comfortably throughout your everyday life and enhance your active lifestyle by increasing your flexibility and range of motion with assisted stretching.',
  'client_theme.ThemeSidebar.learn_more': 'Learn More',
};
