export default {
  name: 'iFlex Stretch Studios',
  siteURL: 'https://www.iflexstretchstudios.com',
  logo: {
    url: 'https://s3.amazonaws.com/upperhand-app/static/images/iflex_stretch_logo.png',
    width: 325,
  },
  login: {
    desktopSignInColor: '#040404',
    mobileSignInColor: '#040404',
  },
  palette: {
    // TODO: remove primary1Color and accent1Color replaace int usages with a new one
    primary1Color: '#040404',
    accent1Color: '#fdeb03',

    primary: {
      main: '#040404',
    },
    secondary: {
      main: '#fdeb03',
    },
  },
  sideNav: {
    selectedColor: '#040404',
  },
  icons: {
    cart: '#040404',
  },
  statusBar: {
    backgroundColor: '#040404',
  },
};
